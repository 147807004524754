export const dataProcess = [
   {  
      accHeader: 'Adquisición de información',
      accBody: 'Adquisición de información de la empresa a través de un cuestionario con el objetivo de conocer sobre su filosofía, entorno, misión, visión, publico objetivo, productos y otros datos relevantes.',
   },
   {  
      accHeader: 'Análisis',
      accBody: 'Analisis de la información obtenida, busqueda de puntos comunes y diferenciadores con respecto a la competencia, sitentización de los valores y objetivos de la empresa.',
   },
   {  
      accHeader: 'Desarrollo de líneas de diseño',
      accBody: 'Desarrollo de diversos conceptos que simbolicen a la marca y bocetación de los mismos.',
  },
  {  
      accHeader: 'Definición',
      accBody: 'Elección de linea de diseño, ajustes y vectorización final.',
  },
  {  
      accHeader: 'Paleta de color y tipografía',
      accBody: 'Elección definiva de paleta de color para formatos digitales y físicos. Elección de la tipografía del logotipo y el eslogan.',
  },
  {  
      accHeader: 'Versiones',
      accBody: 'Creación de versiones de la marca en distintos formatos y sobre distintos fondos.',
  },
  {  
      accHeader: 'Complementos',
      accBody: 'Diseño de elementos complementarios de la marca. Elección de tipografías para utilización en la papelería, web y resto de elementos pertenecientes a la marca. ',
  },
  {  
      accHeader: 'Manual de marca',
      accBody: 'Creación de manual con las versiones del logo original, positivo, negativo, continuo, horizontal, vertical, mínimo, convivencia, grilla, usos incorrectos, paleta de color, tipografía, elementos complementarios y otros.',
  },
  {  
      accHeader: 'Aplicaciones',
      accBody: 'Diseño de papelería y otros items aplicando el logo y los elementos complementarios de la marca previamente realizados.',
  },
   
]
   


export const dataItem = [
   {  
      itemTitle: 'Líneas de diseño',
      itemDesc: 'En las lineas de diseño se trabajo sobre varios conceptos que abocaran de alguna forma al rubro o al nombre de la empresa.',
   },
   {  
      itemTitle: 'Concepto isotipo',
      itemDesc: "Para crear la marca gráfica se tomo como inspiración de la palabra plan, de los que surgió de los elementos relacionados una lista, y de ella el tick de realizado. Para relacionar este elemento con el sector inmobiliario y a su vez darle originalidad al símbolo, se combino con un icono de casa simplificado, en representación de un inmueble, que remplaza al cuadrado sobre el que se traza el tick. De esta forma se comunica que el plan de comprar/vender/alquilar una propiedad se realiza de forma efectiva y rápida",
   },
   {  
      itemTitle: 'Construcción',
      itemDesc: 'El isotipo esta construido a base de formas geométricas puras con el objetivo de aumentar su memorabilidad y permitir su aplicación en múltiples materiales.',
   },
   {  
      itemTitle: 'Color',
      itemDesc: 'Para representar la marca se escogió un verde cian saturado, conocido como verde primavera. Este color funciona tanto sobre blanco como sobre negro, el cual es el segundo color de la marca, utilizado tanto en la forma como en el fondo. Estos dos colores tienen un alto contraste y resultan bastante llamativos, especialmente por la saturación y brillo del verde resaltadas por el negro. ',
   },
   {  
      itemTitle: 'Tipografía',
      itemDesc: 'Versión original y más usada debido a ser la mas legible en tamaños pequeños y permitir realizar aplicaciones como bordado y repujado.',
   },
   {  
      itemTitle: 'Marca gráfica con eslogan',
      itemDesc: 'Le da un atractivo mayor al isotipo y lo asemeja mas aun a la apariencia de una llama. Es la más liviana de las versiones y en la que se destaca mas la gestualidad de la cara.',
   },
   {  
      itemTitle: 'Variantes de color',
      itemSubtitle: 'Positivo',
      itemDesc: "Versión de la marca gráfica sobre fondos claros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.",
   },
   {  
      itemTitle: 'Variantes de color',
      itemSubtitle: 'Negativo',
      itemDesc: "Versión de la marca gráfica sobre fondos oscuros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, logo #ffffff / papel blanco.",
   },

]

export const dataQuote = [
   {  
      Text: 'Si puedes diseñar una cosa, entonces puedes diseñarlo todo, si lo haces bien, perdurará para siempre.',
      Autor: 'Massimo Vignelli',
   }
]